import t from 'zod';

import { CategoryDecoder } from 'src/libs/finbits/Management/FinancialEntries/Categories/types';
import { NewInboxItemDecoder } from 'src/libs/finbits/Management/NewInboxItems/validations';
import { ContactDecoder } from 'src/libs/finbits/Organization/Companies/Contacts/types';
import { PaymentMethod } from 'src/libs/finbits/PaymentMethods/types';
import { PixType } from 'src/libs/finbits/Bank/Pix/types';
import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';
import { BoletoDecoder } from 'src/libs/finbits/Boletos/types';
import { AttachmentDecoder } from 'src/libs/finbits/Management/Attachments/types';
import { ContactType } from 'src/libs/finbits/Organization/Companies/Contacts/enum';

import { SuggestionSource } from './types';

export const EntrySuggestionsDecoder = t.object({
  category: CategoryDecoder.nullish(),
});

export const CategorySuggestionDecoder = t.object({
  id: t.string().nullish(),
  name: t.string(),
  type: t.nativeEnum(BalanceType),
});

export const ContactSuggestionDecoder = t.object({
  id: t.string().nullish(),
  name: t.string().nullish(),
  nickname: t.string().nullish(),
  document: t.string(),
  type: t
    .union([t.literal('international'), t.nativeEnum(ContactType)])
    .nullable(),
});

export const PaymentDetailsSuggestionDecoder = t.object({
  paymentMethod: t.nativeEnum(PaymentMethod).nullish(),
  pixKey: t.string().nullish(),
  pixType: t.nativeEnum(PixType).nullish(),
  accountDocument: t.string().nullish(),
  accountNumber: t.string().nullish(),
  accountType: t.string().nullish(),
  branchNumber: t.string().nullish(),
  paymentDetails: t.string().nullish(),
  routingNumber: t.string().nullish(),
  payeeName: t.string().nullish(),
  boleto: BoletoDecoder.nullish(),
  bankName: t.string().nullish(),
});

export const SuggestionFieldsDecoder = t.object({
  amount: t.number().nullish(),
  comments: t.string().nullish(),
  date: t.string().nullish(),
  relevantDate: t.string().nullish(),
  description: t.string().nullish(),
  category: CategorySuggestionDecoder.nullish(),
  contact: ContactSuggestionDecoder.nullish(),
  paymentDetails: PaymentDetailsSuggestionDecoder.nullish(),
});

export const NewEntrySuggestionDecoder = t.object({
  sourceType: t.nativeEnum(SuggestionSource),
  source: t.union([NewInboxItemDecoder, ContactDecoder, AttachmentDecoder]),
  fields: SuggestionFieldsDecoder,
});

export const NewEntrySuggestionsDecoder = t.array(NewEntrySuggestionDecoder);
