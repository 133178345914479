import type { TransitionProps } from '@mui/material/transitions';
import { Dialog, DialogTitle, IconButton, Slide } from '@mui/material';
import { CloseIcon } from 'src/mui/_icons';
import type { PortalProps } from 'src/ui/PortalProvider';

import type { BillPayable } from 'src/libs/finbits/Bills/types';

import Content from './Content';
import styles from './ConciliationModal.module.scss';

type Props = {
  onConciliate: (bill: BillPayable) => void;
} & PortalProps;

export default function ConciliationModal({
  onClose,
  open = true,
  onExit,
  onConciliate,
}: Props) {
  return (
    <Dialog
      open={open}
      TransitionComponent={Slide}
      TransitionProps={
        {
          direction: 'up',
          onExited: onExit,
        } as TransitionProps
      }
      onClose={onClose}
      maxWidth="xxl"
      fullWidth
      aria-labelledby="modal-title"
    >
      <DialogTitle id="modal-title" variant="subtitle1">
        Vincular à lançamento existente
      </DialogTitle>
      <IconButton
        aria-label="Fechar modal"
        onClick={onClose}
        className={styles.closeIcon}
      >
        <CloseIcon />
      </IconButton>

      <Content onClose={onClose} onConciliate={onConciliate} />
    </Dialog>
  );
}
